/**
 * The application configuration. Do not edit this file unless you know what you are doing.
 * 
 * __CONFIG is a global constant setup by webpack's DefinePlugin depending on the build environment. 
 * @see https://webpack.js.org/plugins/define-plugin/#usage
 */
/**
 * 
 * @type {{}}
 */
const configLoader = __CONFIG || {};

export default configLoader;